<div class="footer">
    <div class="footer-content">
        <div class="footer-column description contact-2"><h4 class="footer-heading"><span class="text-span-30">macartevacances</span>
        </h4>
            <p class="paragraph-8 contact">Hébergements, transports, loisirs... une carte qui permet à tous les salariés
                de réserver facilement leurs vacances sur Internet comme<strong> </strong>en magasin<br><br>OTIUM&nbsp;SAS,
                société immatriculée au registre du commerce et des sociétés de Paris sous le numéro 882 780 554,
                exploitant le nom commercial <span class="text-span-44">macartevacances<br>‍<br></span>OTIUM SAS est
                inscrit au Registre unique des Intermédiaires en Assurance, Banque et Finance (Orias) sous le numéro
                d'immatriculation 20005889 en qualité de Mandataire non exclusif en opérations de banque et en services
                de paiement (MOBSP)</p></div>
        <div class="footer-links">
            <div class="footer-column"><h4 class="footer-heading">À propos</h4><a
                class="link"
                href="{{baseURLForExternalLink}}avantage-de-la-carte">Avantages de la carte</a><a
                class="link" href="{{baseURLForExternalLink}}mentions-legales">Mentions légales</a><a
                class="link" href="{{baseURLForExternalLink}}conditions-generales">CGVU</a><a
                class="link" href="{{baseURLForExternalLink}}politique-de-confidentialite">Politique de
                confidentialité</a><a
                class="link" href="https://blog.macartevacances.fr" target="_blank">Blog</a></div>
            <div class="footer-column"><h4 class="footer-heading">Nous rejoindre</h4><a
                class="link" href="{{baseURLForExternalLink}}comites-sociaux-economiques">Comités d'entreprise</a><a
                class="link"
                href="{{baseURLForExternalLink}}partenaires">Partenaires
                commerciaux</a></div>
            <div class="footer-column contact"><h4 class="footer-heading">Nous contacter</h4>
                <p class="paragraph-8 _2">Du lundi au vendredi de 9h00 à 18h30 :<br><br>01 87 20 00 57<br></p><a
                    class="link mail"
                    href="mailto:contact@macartevacances.fr?subject=Demande%20de%20renseignements">contact@macartevacances.fr</a>
            </div>
        </div>
    </div>
    <div class="footerbottomline">
        <div class="bottom-links"><a class="footer-logo w-inline-block" href="#"></a>
            <div class="terms-links"></div>
            <div class="socialmedia"><a class="socialmedialink-2 w-inline-block" href="#"></a><a
                class="socialmedialink-2 twitter w-inline-block"
                href="#"></a><a
                class="socialmedialink-2 linkedin w-inline-block" href="#"></a><a
                class="socialmedialink-2 instagram w-inline-block"
                href="#"></a>
            </div>
        </div>
    </div>
</div>
