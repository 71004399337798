<div class="row p-4">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-10">
                <ul class="nav nav-pills nav-justified">
                    <li class="nav-item">
                        <div class="nav-link rounded-pill {{step === 'mandataire' ? 'active' : ''}}">Étape
                            1 : Utilisateur
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="nav-link rounded-pill {{step === 'affiliate' ? 'active' : ''}}">Étape 2
                            : Entreprise
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="nav-link rounded-pill {{step === 'compte' ? 'active' : ''}}">Étape 3 :
                            Votre Compte
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
