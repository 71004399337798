import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {LogasComponent} from '@pro/app/auth/pages/logas/logas.component';
import {LogoutComponent} from '@pro/app/auth/pages/logout/logout.component';
import {UiModule} from '@mcv/ui';
import {NotifierModule} from 'angular-notifier';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {IndexComponent} from './pages/index/index.component';
import {LoginComponent} from './pages/login/login.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {AuthService} from './services/auth.service';
import {ConfigurationsService} from './services/configurations.service';
import {environment} from '@pro/environments/environment';
import {SharedModule} from '@pro/app/shared/shared.module';

const configFactory = (configurationsService: ConfigurationsService,
    authService: AuthService): () => Promise<any> => {
    return () => {
        if (authService.isLoggedIn()) {
            return configurationsService.loadAppConfig()
                .toPromise();
        }
    };
};

@NgModule({
    declarations: [
        LoginComponent,
        ResetPasswordComponent,
        IndexComponent,
        LogasComponent,
        LogoutComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        NotifierModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        UiModule,
        SharedModule
    ],
    providers: [
        AuthService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.RECAPTCHA_SITE_KEY
            } as RecaptchaSettings
        },
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            deps: [
                ConfigurationsService,
                AuthService
            ],
            multi: true
        }
    ]
})
export class AuthModule {
}
