<div class="m-5 text-center logo-outside">
    <img alt="" src="{{baseUrl}}/img/logo.png" width="330">
</div>

<div class="container">
    <div class="card border-light m-1 p-4">

        <div class="card-body">

            <form (ngSubmit)="checkCode()" [formGroup]="form" role="form">

                <h2 class="card-title">Création de votre compte macartevacances</h2>

                <mcv-inscription-step [step]=step></mcv-inscription-step>

                <p class="font-weight-light">Avant de continuer, nous devons vérifier la validité de votre email</p>

                <p>Un code vient d'être envoyé sur l'email <b>{{email}}</b>, veuillez le saisir ci-dessous</p>

                <div class="col-md-6 offset-md-3">
                    <div class="form-inline">
                        <label class="pr-4" for="code">Code :</label>
                        <input (keyup.enter)="checkCode()" class="form-control" formControlName="code" id="code"
                               name="code" required
                               type="text">
                    </div>
                </div>

                <div class="form-group pt-4">

                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-md-4">
                            <a (click)="openUpdateEmailDialog($event, resetEmailModal)"
                               class="btn btn-outline-secondary btn-block"
                               href="#">Il y a une erreur dans mon email</a>
                        </div>
                        <div class="col-md-4">
                            <button (click)="sendCode($event);"
                                    [disabled]="sendingCode"
                                    [promiseBtn]="sendingCode"
                                    class="btn btn-outline-secondary btn-block"
                                    type="button">
                                Renvoyer l'email avec le code
                            </button>
                        </div>
                        <div class="col-md-4">
                            <button [disabled]="!form.valid || loading"
                                    [promiseBtn]="loading"
                                    class="btn btn-primary btn-block"
                                    type="submit">
                                Valider le code
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #resetEmailModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title align-self-center">Vous voulez modifier votre email ?</h4>
            <button (click)="modalRef.hide()" aria-label="Close" class="close" data-dismiss="modal" type="button">
                <i aria-hidden="true" class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <form (ngSubmit)="updateMail()" [formGroup]="modalForm" role="form">
                <div class="form-group">
                    <p>Entrez la nouvelle adresse email</p>
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input [mcvInputAutoFocus]="true" class="form-control" formControlName="email" id="email"
                           name="email" placeholder="nom.prenom@societe.com" type="email">
                </div>
                <div class="form-group mt-5">
                    <div class="float-right">
                        <button (click)="modalRef.hide()" class="btn btn-link" data-dismiss="modal" type="button">
                            Annuler
                        </button>
                        <button [disabled]="!modalForm.valid || loading"
                                [promiseBtn]="loading"
                                class="btn btn-primary"
                                type="submit">
                            Soumettre
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
