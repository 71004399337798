import {Component, Inject, OnInit} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CreateStep1Form} from '@mcv/core';
import {McvnotifierService} from '@mcv/ui';
import {AffiliateInscriptionService} from '@mcv/coreservices';
import {LocalstorageService} from '@pro/app/inscription/services/localstorage.service';
import {IntercomService} from '@pro/app/inscription/services/intercom.service';

@Component({
    selector: 'mcv-step1',
    templateUrl: './step1.component.html',
    styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit {

    form: FormGroup = CreateStep1Form(this.fb);
    fromAid: string;
    loading = false;
    step = 'mandataire';

    constructor(@Inject(BASEURL) public baseUrl: string,
                private notifierService: McvnotifierService,
                private router: Router,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private affiliateService: AffiliateInscriptionService,
                private intercomService: IntercomService,
                private localstorageService: LocalstorageService) {
        this.fromAid = this.route.snapshot.queryParams['aid'] ?? this.route.snapshot.params['aid'] ?? null;
    }

    ngOnInit(): void {
        const savedValues = this.localstorageService.checkLocalStorage();
        this.intercomService.bootWithValues(savedValues);
        if (savedValues) {
            this.form.patchValue(savedValues);
            if (savedValues.aid === this.fromAid) {
                return;
            }
        }
        this.form.patchValue(
            {
                aid: this.fromAid
            }
        );
    }

    async doStep1(): Promise<void> {
        try {
            this.loading = true;
            await this.affiliateService.validStep1(this.form.value).toPromise();
            const savedValues = this.localstorageService.checkLocalStorage();
            if (savedValues != null) {
                Object.assign(savedValues, this.form.value);
                this.localstorageService.saveLocalStorage(savedValues);
            } else {
                this.localstorageService.saveLocalStorage(this.form.value);
            }
            await this.router.navigate(['/inscription/recherche']);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.loading = false;
        }
    }

}
