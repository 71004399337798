import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Step1Component} from './pages/step1/step1.component';
import {LandingComponent} from './pages/landing/landing.component';
import {IndexComponent} from '@pro/app/inscription/index.component';
import {Step2Component} from '@pro/app/inscription/pages/step2/step2.component';
import {Step3Component} from '@pro/app/inscription/pages/step3/step3.component';
import {Step4Component} from '@pro/app/inscription/pages/step4/step4.component';
import {Step5Component} from '@pro/app/inscription/pages/step5/step5.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/inscription/compte',
        pathMatch: 'full'
    },
    {
        path: '',
        component: IndexComponent,
        children: [
            {
                path: 'compte/landing',
                component: LandingComponent
            },
            {
                path: 'compte',
                component: Step1Component
            },
            {
                path: 'recherche',
                component: Step2Component
            },
            {
                path: 'emailcode',
                component: Step3Component
            },
            {
                path: 'contrat',
                component: Step4Component
            },
            {
                path: 'password',
                component: Step5Component
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InscriptionRoutingModule {
}
