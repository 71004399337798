import {Component, Inject, OnInit} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {McvnotifierService} from '@mcv/ui';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AffiliateInscriptionService} from '@mcv/coreservices';
import {LocalstorageService} from '@pro/app/inscription/services/localstorage.service';
import {catchError, map} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';
import {IntercomService} from '@pro/app/inscription/services/intercom.service';
import {PdfContrat} from '@mcv/core';

@Component({
    selector: 'mcv-step4',
    templateUrl: './step4.component.html',
    styleUrls: ['./step4.component.scss']
})
export class Step4Component implements OnInit {
    contrat$: Observable<PdfContrat>;
    step = 'compte';
    form: FormGroup;
    loading = false;
    pdfLoadingError = false;

    constructor(@Inject(BASEURL) public baseUrl: string,
                private notifierService: McvnotifierService,
                private router: Router,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private affiliateInscriptionService: AffiliateInscriptionService,
                private localstorageService: LocalstorageService,
                private intercomService: IntercomService) {
        this.form = fb.group(
            {
                cgu: [null, [Validators.required, Validators.requiredTrue]],
                public_id: [null]
            }
        );
        this.form.disable();
        this.form.controls.cgu.disable();
    }

    ngOnInit(): void {
        if (!this.localstorageService.checkNotEmpty(['legalName', 'public_id', 'email', 'affiliate_public_id'])) {
            this.router.navigate(['/inscription/compte']);
        } else {
            const savedValues = this.localstorageService.checkLocalStorage();
            this.intercomService.bootWithValues(savedValues);
            this.form.patchValue(savedValues);
            this.loadContract();
        }
    }

    pdfLoadError(event: Error): void {
        this.pdfLoadingError = event != null;
    }

    loadContract() {
        this.contrat$ = this.affiliateInscriptionService.getContrat()
            .pipe(
                map(r => r.data),
                catchError(e => {
                    this.notifierService.error(e);
                    return EMPTY;
                }));
    }

    async validContrat(): Promise<void> {
        try {
            this.loading = true;
            await this.affiliateInscriptionService.validContrat(this.form.value).toPromise();
            await this.router.navigate(['/inscription/password']);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.loading = false;
        }
    }

    pageChanged($event: number, nbPage: number) {
        if ($event === nbPage) {
            this.form.enable();
            this.form.controls.cgu.enable();
        }
    }

}
