import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {UiModule} from '@mcv/ui';
import {ProfilePasswordComponent} from '@pro/app/shared/components/profile-password/profile-password.component';
import {SearchResultComponent} from '@pro/app/shared/components/search-result/search-result.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {TpeStatPipe} from './pipes/tpe-stat.pipe';
import {ValueOrSepPipe} from './pipes/value-or-sep.pipe';
import {LegalToHtmlPipe} from './pipes/legal-to-html.pipe';
import {MerchantToHtmlPipe} from './pipes/merchant-to-html.pipe';

@NgModule({
    declarations: [
        NavbarComponent,
        SearchResultComponent,
        ProfilePasswordComponent,
        SafeUrlPipe,
        TpeStatPipe,
        ValueOrSepPipe,
        LegalToHtmlPipe,
        MerchantToHtmlPipe
    ],
    exports: [
        NavbarComponent,
        SearchResultComponent,
        ProfilePasswordComponent,
        SafeUrlPipe,
        TpeStatPipe,
        ValueOrSepPipe,
        LegalToHtmlPipe,
        MerchantToHtmlPipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        UiModule,
        ReactiveFormsModule
    ]
})
export class SharedModule {
}
