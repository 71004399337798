import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {ConnectionService} from '@mcv/coreservices';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    disconnected: boolean;

    constructor(private router: Router,
                private connectionService: ConnectionService,
                private authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.connectionService.currentState.hasNetworkConnection) {
            throw new Error('Vous n\'êtes pas connecté à internet');
        }
        const idToken = this.authService.getToken();
        let localRequest: HttpRequest<any>;
        if (idToken) {
            localRequest = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + idToken)
            });

        } else {
            localRequest = request;
        }
        return next.handle(localRequest);
    }
}
