import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {Affiliate, AffiliateUser, DatasourceWrapper, MetaPaging, Tpe} from '@mcv/core';
import {McvnotifierService} from '@mcv/ui';
import {AuthService} from '@pro/app/auth/services/auth.service';
import {AffiliateTpeService} from '@mcv/coreservices';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';

@Component({
    selector: 'mcv-tpes-listing',
    templateUrl: './tpes-listing.component.html',
    styleUrls: ['./tpes-listing.component.scss']
})
export class TpesListingComponent implements OnInit, OnDestroy {
    datasource: DatasourceWrapper<Tpe>;
    paging$: Observable<MetaPaging>;
    limit = 10;
    page = 0;
    sort: { active: string, direction: string } = {
        active: 'idx',
        direction: 'desc'
    };
    searchFilter: FormGroup = this.fb.group({
        filter: []
    });
    subscription: Subscription = new Subscription();
    affiliate: Affiliate;
    user: AffiliateUser;
    loading = false;
    tpeCount$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    showSearchModal: EventEmitter<any> = new EventEmitter<any>();
    modalRef: BsModalRef;

    constructor(private fb: FormBuilder,
                private notifierService: McvnotifierService,
                private authService: AuthService,
                private affiliateTpeService: AffiliateTpeService,
                private router: Router) {
        this.user = authService.user as AffiliateUser;
        this.datasource = new DatasourceWrapper<Tpe>(affiliateTpeService, this.searchFilter);
        this.paging$ = this.datasource.paging$;
    }

    ngOnInit(): void {
        this.loadData();
        this.initFormFilter();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.tpeCount$.unsubscribe();
    }

    sortChange($event: { active: string, direction: string }): void {
        this.sort = $event;
        this.loadData();
    }

    showCreateAndSearchModal() {
        this.showSearchModal.emit();
    }

    showTpeAddView() {
        this.router.navigate(['/pro/tpe/add', {first: this.tpeCount$.value === 0}]);
    }

    tpeWasAdded() {
        this.loadData();
    }

    pageChange($event: PageEvent): void {
        this.limit = $event.pageSize;
        this.page = $event.pageIndex;
        this.loadData();
    }

    async refreshStat(): Promise<void> {
        await this.affiliateTpeService.tpeStats(this.user.affiliate_public_id)
            .pipe(map(r => r.data),
                tap(r => this.tpeCount$.next(r.total_tpe === 0 ? -1 : r.total_tpe))
            )
            .toPromise();
    }

    private async loadData(): Promise<void> {
        await this.datasource.loadData(this.page + 1, this.limit, this.sort.direction, this.sort.active);
        this.refreshStat();
    }

    private initFormFilter(): void {
        this.subscription.add(
            this.searchFilter
                .valueChanges
                .pipe(
                    debounceTime(250),
                    distinctUntilChanged()
                )
                .subscribe(_ => this.loadData())
        );
    }
}
