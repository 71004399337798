import {Component, Inject, OnInit, TemplateRef} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {McvnotifierService} from '@mcv/ui';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AffiliateInscriptionService} from '@mcv/coreservices';
import {LocalstorageService} from '@pro/app/inscription/services/localstorage.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {IntercomService} from '@pro/app/inscription/services/intercom.service';

@Component({
    selector: 'mcv-step3',
    templateUrl: './step3.component.html',
    styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnInit {

    form: FormGroup;
    modalForm: FormGroup;
    email: string;
    step = 'compte';
    loading = false;
    sendingCode = false;
    modalRef: BsModalRef;

    constructor(@Inject(BASEURL) public baseUrl: string,
                private notifierService: McvnotifierService,
                private router: Router,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private affiliateInscriptionService: AffiliateInscriptionService,
                private localstorageService: LocalstorageService,
                private modalService: BsModalService,
                private intercomService: IntercomService) {
        this.form = fb.group(
            {
                code: [null, [Validators.required]],
                public_id: [null]
            }
        );
        this.modalForm = fb.group(
            {
                email: [null, [Validators.required, Validators.email]],
                public_id: [null]
            }
        );
    }

    ngOnInit(): void {
        if (!this.localstorageService.checkNotEmpty(['legalName', 'public_id', 'email', 'affiliate_public_id'])) {
            this.router.navigate(['/inscription/compte']);
        } else {
            const savedValues = this.localstorageService.checkLocalStorage();
            this.intercomService.bootWithValues(savedValues);
            this.email = savedValues.email;
            this.form.patchValue(savedValues);
            this.modalForm.patchValue({
                public_id: savedValues.public_id
            });
        }
    }

    openUpdateEmailDialog($event: MouseEvent, updateEmailModal: TemplateRef<any>): void {
        $event.preventDefault();
        this.modalForm.patchValue({
            email: null,
            public_id: this.form.value.public_id
        });
        this.modalRef = this.modalService.show(updateEmailModal, {class: 'modal-dialog-centered modal-lg'});
    }

    async sendCode($event): Promise<void> {
        $event.preventDefault();
        try {
            this.sendingCode = true;
            await this.affiliateInscriptionService.resendCode(this.form.value).toPromise();
            this.notifierService.success('Nous avons envoyé un nouveau code par email');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.sendingCode = false;
        }
    }

    async checkCode(): Promise<void> {
        try {
            this.loading = true;
            await this.affiliateInscriptionService.checkCode(this.form.value).toPromise();
            await this.router.navigate(['/inscription/contrat']);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.loading = false;
        }
    }

    async updateMail(): Promise<void> {
        try {
            this.loading = true;
            await this.affiliateInscriptionService.updateEmail(this.modalForm.value).toPromise();
            this.modalRef.hide();
            const savedValues = this.localstorageService.checkLocalStorage();
            savedValues.email = this.modalForm.value.email;
            this.localstorageService.saveLocalStorage(savedValues);
            this.email = this.modalForm.value.email;
            this.notifierService.success('Votre email a été mis à jour');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.loading = false;
        }
    }

}
