<!-- Modal -->
<ng-template #searchTpeModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title align-self-center">Enregistrer un nouveau TPE</h4>
            <button (click)="clear();modalRef.hide()" aria-label="Close" class="close" data-dismiss="modal"
                    type="button">
                <i aria-hidden="true" class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <form role="form">
                <div class="form-group">
                    <p>Rechercher un TPE avec votre société</p>
                </div>
                <div class="form-group">
                    <ng-select [(ngModel)]="selectedMerchant"
                               [addTag]="false"
                               [hideSelected]="true"
                               [items]="merchant$ | async"
                               [loading]="loading"
                               [minTermLength]="2"
                               [multiple]="false"
                               [ngModelOptions]="{standalone: true}"
                               [typeahead]="merchantInput$"
                               bindLabel="mid"
                               loadingText="Recherche en cours..."
                               notFoundText="Aucune TPE trouvé avec ce nom"
                               typeToSearchText="Saisissez le nom de votre marchand pour lancer une recherche">
                        <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
                            <div [innerHTML]="item | merchantToHtml"></div>
                        </ng-template>
                    </ng-select>
                </div>
                <ng-container *ngIf="selectedMerchant">
                    <div class="form-group">
                        <label for="merchant-info">Vous avez selectionné :</label>
                        <div class="m-0 mr-2 ml-2 p-4 border border-primary rounded" id="merchant-info">
                            <div [innerHTML]="selectedMerchant | merchantToHtml"></div>
                            <button (click)="saveTPE()"
                                    [disabled]="saving"
                                    [promiseBtn]="saving"
                                    class="btn btn-primary col-12 mt-3"
                                    data-cy="addTpe"
                                    data-dismiss="modal"
                                    type="button">Ajouter
                            </button>
                        </div>
                    </div>
                </ng-container>
                <div class="form-group mt-4">
                    <div class="float-right">
                        <button (click)="clear();modalRef.hide()" [disabled]="saving" class="btn btn-link"
                                data-dismiss="modal" type="button">
                            Annuler
                        </button>
                        <button (click)="modalRef.hide();clear();cantFoundTPE.emit()"
                                [disabled]="saving" class="btn btn-outline-primary" data-dismiss="modal"
                                type="button">
                            Je ne trouve pas mon terminal
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
