import {Injectable} from '@angular/core';

export const LOCALSTORAGE_INSCRIPTION_KEY = 'mcv-pro-inscription';


@Injectable({
    providedIn: 'root'
})
export class LocalstorageService {

    constructor() {
    }

    checkNotEmpty(keys: string[]): boolean {
        const values = this.checkLocalStorage();
        if (!values) {
            return false;
        }
        for (const key of keys) {
            if (values[key] == null)
                return false;
        }
        return true;
    }

    checkLocalStorage(): any {
        const inscription = window.localStorage.getItem(LOCALSTORAGE_INSCRIPTION_KEY);
        if (inscription) {
            return JSON.parse(inscription);
        }
        return null;
    }

    saveLocalStorage(formValue: any): void {
        window.localStorage.setItem(LOCALSTORAGE_INSCRIPTION_KEY, JSON.stringify(formValue));
    }

    clear(): void {
        window.localStorage.removeItem(LOCALSTORAGE_INSCRIPTION_KEY);
    }
}
