import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-nav-bar-inscription',
    templateUrl: './nav-bar-inscription.component.html',
    styleUrls: ['./nav-bar-inscription.component.scss']
})
export class NavBarInscriptionComponent implements OnInit {

    baseURLForExternalLink = 'https://www.macartevacances.fr/';

    constructor() {
    }

    ngOnInit(): void {
    }
}
