import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-index',
    template: `
        <div class="container">
            <router-outlet></router-outlet>
        </div>
    `,
    styles: ['']
})
export class IndexComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
