import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {McvnotifierService} from '@mcv/ui';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService,
                private router: Router,
                private notifierService: McvnotifierService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(catchError(err => {
                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                    this.authService.logout();
                    this.notifierService.error('Vous avez été déconnecté, retour à la page de connexion');
                    this.router.navigate(['/auth/login']);
                }

                const error = err.error.message || err.statusText;
                return throwError(err);
            }));
    }
}
