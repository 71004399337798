<ng-container *ngIf="tpeCount$ | async as count; else loading">
    <div *ngIf="count < 0;else notEmpty">
        <div class="container p-4">
            <div class="row">
                <div class="col">
                    <div class="card card-empty-user">
                        <div class="card-body align-middle">
                            <h5 class="card-title">Vous n'avez pas encore enregistré de TPE</h5>
                            <button (click)="showCreateAndSearchModal()" class="btn btn-primary mt-3"
                                    type="button">Enregistrez votre premier
                                terminal de paiement
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #notEmpty>
        <div class="p-4 bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-sm-9">
                        <h2>{{count}} TPE enregistré{{count > 1 ? 's' : ''}} dans votre compte</h2>
                    </div>
                    <div class="col-sm-3">
                        <div class="float-right">
                            <a (click)="showCreateAndSearchModal()" class="btn btn-primary">Enregister un nouveau
                                TPE</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-4 card-body">
                <div class="row">
                    <div class="card border-light">
                        <div class="card-body">
                            <div [formGroup]="searchFilter" class="row justify-content-between">
                                <div class="col-6">
                                    <div class="input-group white-input-group mb-3">
                                        <div class="input-group-prepend">
                            <span class="input-group-text" id="search-bar">
                                <i aria-hidden="true" class="fas fa-search"></i>
                            </span>
                                        </div>
                                        <input class="form-control"
                                               formControlName="filter"
                                               placeholder="Recherchez un tpe..."
                                               type="search"/>
                                    </div>
                                </div>
                            </div>
                            <mcv-tpes-listing-table (pageChange)="pageChange($event)"
                                                    (sortChange)="sortChange($event)"
                                                    [datasourceWrapper]="datasource"
                                                    [filter]="searchFilter.value.filter"></mcv-tpes-listing-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>


<ng-template #loading>
    <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

<mcv-tpe-search-modal (cantFoundTPE)="showTpeAddView()" (validTPE)="tpeWasAdded()"
                      [showModel]="showSearchModal"></mcv-tpe-search-modal>
