<div class="m-5 text-center logo-outside">
    <img alt="" src="{{baseUrl}}/img/logo.png" width="330">
</div>

<div class="container">
    <div class="card border-light m-1 p-4">

        <div class="card-body">

            <img alt="" class="logo-inside float-right" src="{{baseUrl}}/img/logo.png" width="300">
            <h2 class="card-title">Création de votre compte affilié macartevacances</h2>

            <mcv-inscription-step [step]=step></mcv-inscription-step>

            <div class="form-group">
                <label for="legalName">
                    Bonjour {{firstname}}, veuillez nous indiquer le nom de votre entreprise référente
                    (Dénomination sociale)
                </label>
                <ng-select [(ngModel)]="selectedBusiness"
                           [addTag]="false"
                           [hideSelected]="true"
                           [items]="business$ | async"
                           [loading]="loading"
                           [minTermLength]="2"
                           [multiple]="false"
                           [typeahead]="businessInput$"
                           bindLabel="legalName"
                           data-cy="legalName"
                           id="legalName"
                           loadingText="Recherche en cours..."
                           notFoundText="Aucune entreprise trouvée avec ce nom"
                           typeToSearchText="Saisissez le nom de votre entreprise pour lancer une recherche">
                    <ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
                        <div [innerHTML]="item | legalToHtml"></div>
                    </ng-template>
                </ng-select>
            </div>

            <div class="form-group">
                Si vous ne trouvez pas votre entreprise, vous pouvez aussi saisir le <b>numéro de SIRET</b>
            </div>

            <ng-container *ngIf="selectedBusiness">
                <div class="form-group" id="cse-preview">
                    <label for="cse-info">Vous avez selectionné :</label>
                    <div class="m-0 mr-2 ml-2 p-4 border border-primary rounded" id="cse-info">
                        <b>{{selectedBusiness.business.legalName}}</b>
                        SIRET: {{(selectedBusiness.business.legalRegistrationNumber.substr(0, 9)) | imask:mask }}
                        <br/><small>{{selectedBusiness.business.address1}}
                        , {{selectedBusiness.business.postcode}} {{selectedBusiness.business.city}}</small>
                    </div>
                </div>
                <button (click)="doStep2()"
                        [disabled]="saving"
                        [promiseBtn]="saving"
                        class="btn btn-primary btn-block"
                        data-cy="nextStep">Continuer
                </button>
            </ng-container>


        </div>
    </div>
</div>

