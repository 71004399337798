import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IndexComponent} from './index.component';
import {TpesListingComponent} from '@pro/app/pro/tpe/pages/tpes-listing/tpes-listing.component';
import {TpeEditionComponent} from '@pro/app/pro/tpe/pages/tpe-edition/tpe-edition.component';

const routes: Routes = [
    {
        path: '',
        component: IndexComponent,
        children: [
            {path: 'list', component: TpesListingComponent},
            {path: 'edit/:id', component: TpeEditionComponent},
            {path: 'add', component: TpeEditionComponent}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TpeRoutingModule {
}
