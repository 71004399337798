import {Component, Inject, OnInit} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {McvnotifierService} from '@mcv/ui';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AffiliateInscriptionService} from '@mcv/coreservices';
import {LocalstorageService} from '@pro/app/inscription/services/localstorage.service';
import {CreatePasswordForm} from '@mcv/core';
import {AuthService} from '@pro/app/auth/services/auth.service';
import {ConfigurationsService} from '@pro/app/auth/services/configurations.service';
import {map} from 'rxjs/operators';
import {IntercomService} from '@pro/app/inscription/services/intercom.service';

@Component({
    selector: 'mcv-step5',
    templateUrl: './step5.component.html',
    styleUrls: ['./step5.component.scss']
})
export class Step5Component implements OnInit {

    form: FormGroup;
    step = 'compte';
    saving = false;

    constructor(@Inject(BASEURL) public baseUrl: string,
                private notifierService: McvnotifierService,
                private router: Router,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private affiliateInscriptionService: AffiliateInscriptionService,
                private localstorageService: LocalstorageService,
                private authService: AuthService,
                private configurationService: ConfigurationsService,
                private intercomService: IntercomService) {
    }

    ngOnInit(): void {
        const savedValues = this.localstorageService.checkLocalStorage();
        this.intercomService.bootWithValues(savedValues);
        if (savedValues) {
            this.form = CreatePasswordForm(this.fb);
            this.form.patchValue(savedValues);
        } else {
            this.router.navigate(['/inscription/compte']);
        }
    }

    async savePassword(): Promise<void> {
        try {
            this.saving = true;
            const token = await this.affiliateInscriptionService.finishInscription(this.form.value).pipe(map(r => r.data)).toPromise();
            this.authService.setSession(token);
            await this.configurationService.loadAppConfig().toPromise();
            this.notifierService.success('Votre compte a été créé ! vous êtes redirigé vers votre tableau de bord');
            this.localstorageService.clear();
            this.router.navigate(['/pro']);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }
}
