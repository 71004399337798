import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {TableComponent, Tpe} from '@mcv/core';
import {BASEURL} from '@mcv/config';

@Component({
    selector: 'mcv-tpes-listing-table',
    templateUrl: './tpes-listing-table.component.html',
    styleUrls: ['./tpes-listing-table.component.scss']
})
export class TpesListingTableComponent extends TableComponent<Tpe> implements OnInit {

    @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();

    limit = 10;
    page = 0;

    displayedColumns: string[] = [
        'icon',
        'name',
        'date_create',
        'merchantId',
        'terminalId',
        'state',
        'actions'
    ];

    constructor(@Inject(BASEURL) public baseurl: string) {
        super();
    }

    trackBy = (row: Tpe) => row.public_id;

    ngOnInit(): void {
        this.paging$ = this.datasourceWrapper.paging$;
        this.subscription.add(this.sort.sortChange.subscribe(sort => this.sortChange.emit(sort)));
    }

}
