import {Component, Inject, OnInit} from '@angular/core';
import {BASEURL} from '@mcv/config';

@Component({
    selector: 'mcv-index',
    template: `
            <router-outlet></router-outlet>
    `,
    styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

    constructor(@Inject(BASEURL) public baseUrl: string) {
    }

    ngOnInit(): void {
    }

}
