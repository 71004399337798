import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@pro/app/auth/guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/pro/tableau-de-bord',
        pathMatch: 'full'
    },
    {
        path: 'pro',
        canLoad: [AuthGuard],
        loadChildren: () => import('./pro/pro.module').then(m => m.ProModule)
    },
    {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    },
    {
        path: 'inscription',
        loadChildren: () => import('./inscription/inscription.module').then(m => m.InscriptionModule)
    },
    {
        path: '**',
        redirectTo: '/pro/tableau-de-bord'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {enableTracing: false, relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
