import {Component, Inject, OnInit} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {McvnotifierService} from '@mcv/ui';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AffiliateInscriptionService} from '@mcv/coreservices';
import {LocalstorageService} from '@pro/app/inscription/services/localstorage.service';
import {CreateStep2Form, Legal} from '@mcv/core';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {concat, Observable, of, Subject} from 'rxjs';
import {IntercomService} from '@pro/app/inscription/services/intercom.service';

@Component({
    selector: 'mcv-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit {
    form: FormGroup = CreateStep2Form(this.fb);
    business$: Observable<Legal[]>;
    businessInput$: Subject<string> = new Subject<string>();
    selectedBusiness: Legal;
    loading = false;
    saving = false;
    firstname: string;
    step = 'affiliate';
    mask = {
        placeholderChar: '_',
        lazy: false,
        mask: '000 000 000'
    };

    constructor(@Inject(BASEURL) public baseUrl: string,
                private localstorageService: LocalstorageService,
                private affiliateInscriptionService: AffiliateInscriptionService,
                private notifierService: McvnotifierService,
                private router: Router,
                private fb: FormBuilder,
                private intercomService: IntercomService) {
    }

    ngOnInit(): void {
        if (!this.localstorageService.checkNotEmpty(['firstname', 'lastname', 'email', 'mobile'])) {
            this.router.navigate(['/inscription/compte']);
        } else {
            const savedValues = this.localstorageService.checkLocalStorage();
            this.intercomService.bootWithValues(savedValues);
            this.form.patchValue(savedValues);
            this.firstname = savedValues.firstname;
            this.loadPeople();
            if (savedValues.legal) {
                this.selectedBusiness = savedValues.legal;
                this.form.patchValue({
                    legalRegistrationNumber: this.selectedBusiness.value
                });
            }
        }
    }

    async doStep2(): Promise<void> {
        try {
            this.saving = true;
            this.form.patchValue({
                legalRegistrationNumber: this.selectedBusiness.value
            });
            const affiliateInfo = await this.affiliateInscriptionService.validStep2(this.form.value).pipe(map(r => r.data)).toPromise();
            const savedValues = this.localstorageService.checkLocalStorage();
            Object.assign(savedValues, this.form.value, affiliateInfo.affiliate, affiliateInfo.affiliateUser, {
                legal: this.selectedBusiness
            });
            this.localstorageService.saveLocalStorage(savedValues);
            if (affiliateInfo.affiliateUser.email_verified) {
                await this.router.navigate(['/inscription/contrat']);
            } else {
                await this.router.navigate(['/inscription/emailcode']);
            }
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }

    private loadPeople() {
        this.business$ = concat(
            of([]), // default items
            this.businessInput$.pipe(
                filter(v => !!v),
                debounceTime(500),
                distinctUntilChanged(),
                tap(_ => this.loading = true),
                switchMap(term => this.affiliateInscriptionService.searchLegal(term)),
                map(
                    r => r.data
                ),
                catchError(_ => of([])), // empty list on error
                tap(_ => this.loading = false)
            )
        );
    }

}
