<div class="m-5 text-center logo-outside">
    <img alt="" src="{{baseUrl}}/img/logo.png" width="330">
</div>

<div class="container">
    <div class="card border-light m-1 p-4">

        <div class="card-body">

            <form (ngSubmit)="validContrat()" [formGroup]="form" role="form">

                <h2 class="card-title">Conditions d'utilisation de macartevacances</h2>

                <mcv-inscription-step [step]=step></mcv-inscription-step>

                <div class="card-deck">
                    <div class="card mb-3 mx-auto" style="max-width: 700px;">
                        <div class="row no-gutters">
                            <div class="col-md-4">
                                <img alt="..." class="card-img p-4" src="{{baseUrl}}/img/Money-1.png">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">

                                    <h5 class="card-title">Une commission simple et transparente</h5>
                                    <p class="card-text">Uniquement <b>3,15%</b> sur les transactions. Sans frais
                                        fixes. Avec macartevacances, tout est compris.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="contrat$ | async as contrat; else loadingContainer">
                    <ng-container *ngIf="pdfLoadingError; else mainContent">
                        <div class="text-danger text-center">Un problème est arrivé lors du chargement du contrat,
                            veuillez recharger la page ou contacter le support
                        </div>
                    </ng-container>
                    <ng-template #mainContent>
                        <div class="form-group pt-4">
                            <div class="text-center h3 pt-4">Pour accepter le contrat vous devez en prendre connaissance
                                jusque la dernière page.
                            </div>
                            <ngx-extended-pdf-viewer
                                (pageChange)="pageChanged($event, contrat.nbPages)"
                                (pdfLoadingFailed)="pdfLoadError($event)"
                                [src]="contrat.filename"
                                [useBrowserLocale]="true"
                                class="border-primary"
                                height="600px">
                            </ngx-extended-pdf-viewer>
                        </div>

                        <div class="form-group">
                            <div class="custom-control custom-switch">
                                <input class="custom-control-input" formControlName="cgu" id="cgu" name="cgu"
                                       required
                                       type="checkbox">
                                <label class="custom-control-label" for="cgu">J'accepte les conditions d'utilisation
                                    macartevacances</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    &nbsp;
                                </div>
                                <div class="col-md-4">
                                    <button [disabled]="!form.valid || loading"
                                            [promiseBtn]="loading"
                                            class="btn btn-primary"
                                            type="submit">
                                        Continuer
                                    </button>
                                </div>
                                <div class="col-md-4">

                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-template #loadingContainer>
                    <div class="w-100 h-100 d-flex align-items-center justify-content-center">
                        <mat-spinner></mat-spinner>
                        Contrat en cours de chargement
                    </div>
                </ng-template>
            </form>
        </div>
    </div>
</div>
