<div class="m-5 text-center logo-outside">
    <img alt="" src="{{baseUrl}}/img/logo.png" width="330">
</div>

<div class="container">
    <div class="card border-light m-1 p-4">

        <div class="card-body">

            <img alt="" class="logo-inside float-right" src="{{baseUrl}}/img/logo.png" width="300">

            <form (ngSubmit)="doStep1()" [formGroup]="form" role="form">

                <div class="container pt-4 pb-4">
                    <div class="text-center">
                        <h1>Professionnels du Tourisme, des Loisirs<br>et de la culture</h1>
                        <h2>Devenez affilié macartevacances</h2>
                    </div>
                </div>

                <div class="container pt-4 pb-4">
                    <div class="row">
                        <div class="col-md-4 text-center">
                            <img class="img-fluid" src="{{baseUrl}}/img/image22.jpeg" style="width: 45%">
                            <h5 class="text-primary pt-3">Simple, rapide et transparent</h5>
                            <div class="content-sub">Fini le papier, encaissez directement les dépenses</div>
                        </div>
                        <div class="col-md-4 text-center">
                            <img class="img-fluid" src="{{baseUrl}}/img/image23.jpeg" style="width: 45%">
                            <h5 class="text-primary pt-3">Une commission attractive</h5>
                            <div class="content-sub">Uniquement 3,15% sur les transactions. Sans frais fixes</div>
                        </div>
                        <div class="col-md-4 text-center">
                            <img class="img-fluid" src="{{baseUrl}}/img/image19.jpeg" style="width: 45%">
                            <h5 class="text-primary pt-3">Partenaire Mastercard<sup>®</sup></h5>
                            <div class="content-sub">Aucune mise à jour nécessaire du terminal de paiement</div>
                        </div>
                    </div>
                </div>

                <mcv-inscription-step [step]=step></mcv-inscription-step>

                <p class="">Renseignez les informations du compte</p>


                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="firstname">Prénom<small>*</small></label>
                        <input class="form-control" formControlName="firstname" id="firstname" name="firstname"
                               required type="text">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="lastname">Nom<small>*</small></label>
                        <input class="form-control" formControlName="lastname" id="lastname" name="lastname" required
                               type="text">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="email">Email<small>*</small></label>
                        <input class="form-control" formControlName="email" id="email" name="email" required
                               type="email">
                        <small>Nous avons besoin de votre email pour créer votre compte</small>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="mobile">Téléphone mobile<small>*</small></label>
                        <mcv-phone-input data-cy="mobile" formControlName="mobile" id="mobile"></mcv-phone-input>
                        <small>Nous avons besoin de votre numéro de mobile pour vérifier votre identité</small>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-md-6 offset-md-3">
                        <div class="form-group">
                            <button [disabled]="!form.valid || loading"
                                    [promiseBtn]="loading"
                                    class="btn btn-primary btn-block"
                                    type="submit">
                                Continuer
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <small>*: mention obligatoire</small>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
