import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LogasComponent} from '@pro/app/auth/pages/logas/logas.component';
import {LogoutComponent} from '@pro/app/auth/pages/logout/logout.component';
import {LoginComponent} from './pages/login/login.component';
import {IndexComponent} from './pages/index/index.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {AutoLoginGuard} from '@pro/app/auth/guards/autoLogin.guard';

const routes: Routes = [
    {
        path: 'auth',
        component: IndexComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [AutoLoginGuard]
            },
            {path: 'password', component: ResetPasswordComponent},
            {path: 'logas/:id', component: LogasComponent},
            {path: 'logout', component: LogoutComponent}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
