<link href="https://uploads-ssl.webflow.com/5dd310bf0e47ad35614be392/css/macartevacances2.webflow.beae120cb.css"
      rel="stylesheet" type="text/css"/>

<div class="navbar w-nav" data-animation="default" data-collapse="medium" data-duration="400" role="banner">
    <a aria-current="page"
       aria-label="home"
       class="brand w-nav-brand w--current"
       href="{{baseURLForExternalLink}}">
        <div class="header-logo"></div>
    </a>
    <div class="nav-links _2">
        <a class="link-2" href="{{baseURLForExternalLink}}/faq">Des questions ?</a>
        <a class="nav-link-2 w-nav-link" href="{{baseURLForExternalLink}}/mon-compte">Mon compte</a>
    </div>
    <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
</div>
