import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BASEURL} from '@mcv/config';
import {CreatePasswordForm} from '@mcv/core';
import {AuthService} from '../../services/auth.service';
import {McvnotifierService} from '@mcv/ui';

@Component({
    selector: 'mcv-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    passForm: FormGroup;
    saving = false;
    update = true;

    constructor(private authService: AuthService,
                private fb: FormBuilder,
                @Inject(BASEURL) public baseUrl: string,
                private router: Router,
                private notifierService: McvnotifierService,
                private activatedRoute: ActivatedRoute) {
        const {id, key} = this.activatedRoute.snapshot.queryParams;
        this.passForm = CreatePasswordForm(this.fb);
        this.passForm.addControl('key', this.fb.control({}));
        this.passForm.patchValue({public_id: id, key});
    }

    ngOnInit(): void {
    }

    async resetPassword(): Promise<void> {
        try {
            this.saving = true;
            await this.authService.confirmPasswordReset(this.passForm.value)
                .toPromise();
            this.notifierService.success('Mot de passe modifié avec succès, vous pouvez utiliser votre nouveau mot de passe pour vous connecter');
            this.router.navigate(['/auth/login']);
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }
}
