import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'valueOrSep'
})
export class ValueOrSepPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (value == null || value?.length === 0) {
            return '-';
        }
        return value;
    }

}
