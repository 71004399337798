import {Component, Inject, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {McvnotifierService} from '@mcv/ui';
import {ConfigurationsService} from '../../services/configurations.service';
import {AuthService} from '../../services/auth.service';
import {BASEURL} from '@mcv/config';
import {map} from 'rxjs/operators';

@Component({
    selector: 'mcv-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup = this.fb.group({
        email: [null, [Validators.required]],
        password_hash: [null, [Validators.required]],
        recaptchaReactive: [],
        remember_me: []
    });
    modalRef: BsModalRef;
    lostPasswordForm: FormGroup = this.fb.group({
        email: [null, [Validators.required]]
    });
    saving = false;
    subscription: Subscription = new Subscription();
    improveSecurity = false;
    loading = false;

    constructor(@Inject(BASEURL) public baseUrl: string,
                private fb: FormBuilder,
                private router: Router,
                private modalService: BsModalService,
                private configurationService: ConfigurationsService,
                private notifierService: McvnotifierService,
                private authService: AuthService) {
    }

    async ngOnInit(): Promise<void> {
        if (await this.authService.checkIp()
            .pipe(map(r => r.data.mustCaptcha))
            .toPromise()) {
            return this.reinforceSecurity();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    login(): void {
        this.loading = true;
        this.authService
            .login(this.loginForm.value)
            .toPromise()
            .then(_ => {
                return this.configurationService.loadAppConfig()
                    .toPromise();
            })
            .then(_ => {
                this.router.navigate(['/pro']);
                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                this.notifierService.error(err);
                this.reinforceSecurity();
            });
    }

    reinforceSecurity() {
        this.improveSecurity = true;
        this.loginForm.get('recaptchaReactive')
            .setValidators([Validators.required]);
    }


    openLostPasswordDialog($event: MouseEvent, lostPasswordModal: TemplateRef<any>): void {
        $event.preventDefault();
        this.modalRef = this.modalService.show(lostPasswordModal, {class: 'modal-dialog-centered modal-lg'});
    }

    async sendLostPassword(): Promise<any> {
        try {
            this.saving = true;
            await this.authService.resetPassword(this.lostPasswordForm.value)
                .toPromise();
            this.notifierService.success('Un email vient de vous être envoyé');
            this.modalRef.hide();
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }
}
