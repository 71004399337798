import {Component, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Affiliate, CreateTpeForm, Tpe} from '@mcv/core';
import {map} from 'rxjs/operators';
import {McvnotifierService} from '@mcv/ui';
import {AuthService} from '@pro/app/auth/services/auth.service';
import {AffiliateTpeService} from '@mcv/coreservices';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'mcv-tpe-edition',
    templateUrl: './tpe-edition.component.html',
    styleUrls: ['./tpe-edition.component.scss']
})
export class TpeEditionComponent implements OnInit {

    tpeForm: FormGroup = CreateTpeForm(this.fb);
    currentTPE: Tpe;
    editing = false;
    public_id: string;
    loading = false;
    saving = false;
    deleting = false;
    firstTpe = false;
    affiliate: Affiliate;
    modalRef: BsModalRef;
    currentName: string;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private notifierService: McvnotifierService,
                private authService: AuthService,
                private affiliateTpeService: AffiliateTpeService,
                private modalService: BsModalService,
                private fb: FormBuilder) {
        this.public_id = this.route.snapshot.paramMap.get('id');
        this.firstTpe = this.route.snapshot.paramMap.get('first') === 'true';
        this.editing = this.public_id != null;
        this.affiliate = authService.affiliate;
    }

    async ngOnInit(): Promise<void> {
        await this.loadData();
    }

    async loadData(): Promise<void> {
        try {
            this.loading = true;
            if (this.editing) {
                this.currentTPE = await this.affiliateTpeService.view(this.public_id).pipe(map(r => r.data)).toPromise();
                this.tpeForm.patchValue(this.currentTPE);
            } else {
                const newName = await this.affiliateTpeService.newTpename(this.affiliate.public_id).pipe(map(r => r.data)).toPromise();
                this.tpeForm.patchValue({
                    name: newName.name
                });
            }
            this.currentName = this.tpeForm.value.name;
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.loading = false;
        }
    }

    async saveTPE(): Promise<void> {
        try {
            this.saving = true;
            await this.affiliateTpeService.add(this.tpeForm.value).toPromise();
            if (this.editing) {
                this.notifierService.success('Votre terminal de paiement a bien été mis à jour');
            } else {
                this.notifierService.success('Votre terminal de paiement a bien été créé');
            }
            await this.router.navigateByUrl('/pro/tpe/list');
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }

    async deleteTPE($event): Promise<void> {
        $event.preventDefault();
        if (this.editing) {
            try {
                this.deleting = true;
                await this.affiliateTpeService.delete(this.public_id).toPromise();
                this.notifierService.success('Votre terminal de paiement a bien été supprimé');
                this.modalRef.hide();
                await this.router.navigateByUrl('/pro/tpe/list');
            } catch (e) {
                this.notifierService.error(e);
            } finally {
                this.deleting = false;
            }
        }
    }

    openDeleteTpeDialog($event: MouseEvent, updateEmailModal: TemplateRef<any>): void {
        $event.preventDefault();
        this.modalRef = this.modalService.show(updateEmailModal, {class: 'modal-dialog-centered modal-lg'});
    }

}
