<div class="p-4 bg-white">

    <div class="container">

        <div class="row-12">

            <div class="col-sm">
                <h2>{{
                    editing ? 'Gestion de votre TPE' : firstTpe ? 'Création de votre premier terminal de paiement' : 'Création d\'un terminal de paiement'
                    }}</h2>
            </div>

        </div>

    </div>

</div>

<div class="container">

    <div class="main-content p-4">
        <div class="card-body bg-white">
            <form (ngSubmit)="saveTPE()" [formGroup]="tpeForm" class="form-horizontal">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="name">Libellé du TPE<small>*</small></label>
                        <input class="form-control" formControlName="name" id="name" maxlength="40" required trim="blur"
                               type="text">
                        <val-errors controlName="name"></val-errors>
                    </div>
                </div>

                <div
                    class="h4 mb-3 text-center">{{ !editing ? 'Rapprochez de votre prestataire pour connaitre votre identifiant de paiement.' : '' }}</div>

                <div class="form-row m-4">
                    Vous pouvez directement indiquer votre identifiant marchand, nous regrouperons alors automatiquement
                    toutes les dépenses de tous vos terminaux qui y sont associés.<br/>
                    Vous pouvez aussi indiquer votre identifiant de terminal afin de pouvoir mieux identifier les
                    dépenses associées à vos différents terminaux de paiement.
                </div>

                <div class="form-row">
                    <div class="form-group col-md-5">
                        <label for="merchantId">Identifiant marchand<small>*</small></label>
                        <input class="form-control" formControlName="merchantId" id="merchantId" maxlength="20"
                               trim="blur" type="text">
                        <label for="merchantId"><small>Identifiant marchand chez visa/mastercard</small></label>
                        <val-errors controlName="merchantId"></val-errors>
                    </div>
                    <div class="form-group col-md-2 text-center">
                        et/ou
                    </div>
                    <div class="form-group col-md-5">
                        <label for="terminalId">Identifiant du terminal de paiement<small>*</small></label>
                        <input class="form-control" formControlName="terminalId" id="terminalId" maxlength="20"
                               trim="blur" type="text">
                        <label for="terminalId"><small>Identifiant du terminal de paiement chez visa/mastercard</small></label>
                        <val-errors controlName="terminalId"></val-errors>
                    </div>
                </div>
                <div class="form-row m-4">
                    Attention, une fois validé par l’équipe macartevacances, vous ne pourrez plus modifier un terminal
                    de paiement sans faire une demande au support.
                </div>

                <div class="form-row mt-3 row-12">
                    <div class="col-md-12">
                        <button [disabled]="!tpeForm.valid || saving"
                                [promiseBtn]="saving"
                                class="btn btn-primary btn-block"
                                type="submit">
                            {{editing ? 'Mettre à jour les informations du terminal' : 'Ajouter un nouveau terminal'}}

                        </button>
                    </div>
                </div>

                <ng-container *ngIf="editing">
                    <div class="form-row mt-3">
                        <div class="col-md-12">
                            <button (click)="openDeleteTpeDialog($event, deleteTpeModal)"
                                    class="btn btn-outline-danger btn-block"
                                    type="button">
                                Supprimer ce terminal
                            </button>
                        </div>
                    </div>
                </ng-container>

                <div class="form-row">
                    <div class="col">
                        <small>*: mention obligatoire</small>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>


<div class="pt-5">&nbsp;</div>

<!-- Modal -->
<ng-template #deleteTpeModal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title align-self-center">Voulez-vous supprimer ce TPE ?</h4>
            <button (click)="modalRef.hide()" aria-label="Close" class="close" data-dismiss="modal" type="button">
                <i aria-hidden="true" class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <form (ngSubmit)="deleteTPE($event)" role="form">
                <div class="form-group">
                    <p>Nom du TPE : {{currentName}}</p>
                </div>
                <div class="form-group mt-5">
                    <div class="float-right">
                        <button (click)="modalRef.hide()" class="btn btn-link" data-dismiss="modal" type="button">
                            Annuler
                        </button>
                        <button [promiseBtn]="deleting"
                                class="btn btn-danger"
                                type="submit">
                            Supprimer
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

