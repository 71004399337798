import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'mcv-footer-inscription',
    templateUrl: './footer-inscription.component.html',
    styleUrls: ['./footer-inscription.component.scss']
})
export class FooterInscriptionComponent implements OnInit {

    baseURLForExternalLink = 'https://www.macartevacances.fr/';

    constructor() {
    }

    ngOnInit(): void {
    }

}
