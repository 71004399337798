<mcv-progress-bar [loadingIndicator]="datasourceWrapper.loading$ | async"></mcv-progress-bar>
<table [dataSource]="datasourceWrapper"
       cdk-table
       class="table table-borderless table-bigrow table-bigrow-hover"
       matSort
       matSortActive=""
       matSortDirection="asc"
       matSortDisableClear>
    <ng-container cdkColumnDef="icon">
        <th *cdkHeaderCellDef cdk-header-cell class="text-center card-header" disabled mat-sort-header="">
        </th>
        <td *cdkCellDef="let element" cdk-cell>
            <div class="square-48 rounded-circle bg-light">
                <i class="fas fa-credit-card"></i>
            </div>
        </td>
    </ng-container>
    <ng-container cdkColumnDef="name">
        <th *cdkHeaderCellDef cdk-header-cell class="text-center card-header" mat-sort-header="name">
            <div class="text-center">TPE</div>
        </th>
        <td *cdkCellDef="let element" cdk-cell>
            <div>{{element.name}}</div>
        </td>
    </ng-container>

    <ng-container cdkColumnDef="date_create">
        <th *cdkHeaderCellDef cdk-header-cell class="text-center card-header" mat-sort-header="date_create">
            <div class="text-center">Date de création</div>
        </th>
        <td *cdkCellDef="let element" cdk-cell>
            <div class="text-center">{{element.date_create |  date:'d MMMM y' | titlecase}}</div>
        </td>
    </ng-container>

    <ng-container cdkColumnDef="terminalId">
        <th *cdkHeaderCellDef cdk-header-cell class="text-center card-header" mat-sort-header="terminalId">
            <div class="text-center">ID terminal</div>
        </th>
        <td *cdkCellDef="let element" cdk-cell>
            <div class="text-center">{{element.terminalId | valueOrSep }}</div>
        </td>
    </ng-container>

    <ng-container cdkColumnDef="merchantId">
        <th *cdkHeaderCellDef cdk-header-cell class="text-center card-header" mat-sort-header="merchantId">
            <div class="text-center">ID marchand</div>
        </th>
        <td *cdkCellDef="let element" cdk-cell>
            <div class="text-center">{{element.merchantId | valueOrSep }}</div>
        </td>
    </ng-container>

    <ng-container cdkColumnDef="state">
        <th *cdkHeaderCellDef cdk-header-cell class="text-center card-header" mat-sort-header="state">
            <div class="text-center">Statut</div>
        </th>
        <td *cdkCellDef="let element" cdk-cell>
            <div [innerHTML]="element.state | tpeStat"></div>
        </td>
    </ng-container>

    <ng-container cdkColumnDef="actions">
        <th *cdkHeaderCellDef cdk-header-cell class="text-center card-header">
            <div class="text-center">Action</div>
        </th>
        <td *cdkCellDef="let element" cdk-cell>
            <div class="float-right">
                <a [routerLink]="'/pro/tpe/edit/'+ element.public_id" class="btn btn-outline-primary">Gérer</a>
            </div>
        </td>
    </ng-container>

    <tr *matNoDataRow class="mat-row">
        <td class="mat-cell text-center" colspan="7">
            Pas d’élément correspondant à votre recherche
        </td>
    </tr>

    <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
    <tr *cdkRowDef="let row; columns: displayedColumns" cdk-row></tr>
</table>

<ng-container *ngIf="paging$ | async as paging">
    <mat-paginator (page)="changePage($event)"
                   *ngIf="paging.count > limit"
                   [color]="'primary'"
                   [length]="paging?.count"
                   [pageSizeOptions]="pageSizeOptions"
                   [pageSize]="limit">
    </mat-paginator>
</ng-container>

<ng-template #loading>
    <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
