<div class="m-5 text-center">
    <img alt="" src="{{baseUrl}}/img/logo.png" width="330">
</div>
<div class="container">
    <form (ngSubmit)="resetPassword()" [formGroup]="passForm">
        <div class="card border-light rounded-1 mt-4">
            <div class="card-body">
                <h2 class="card-title">
                    {{update ? 'Réinitialisation de votre mot de passe' : 'Création du mot de passe'}}
                </h2>
                <p class="">Veuillez créer votre nouveau mot de passe</p>
                <div class="form-group">
                    <label class="control-label" for="password">
                        {{update ? 'Nouveau' : 'Choix du'}} mot de passe
                    </label>
                    <input autocomplete="new-password"
                           class="form-control"
                           data-cy="password"
                           formControlName="password"
                           id="password"
                           name="password"
                           type="password">
                </div>
                <div class="form-group">
                    <label class="control-label" for="password2">Confirmer le mot de passe</label>
                    <input autocomplete="new-password"
                           class="form-control"
                           data-cy="password2"
                           formControlName="password2"
                           id="password2"
                           name="password2"
                           type="password">
                </div>
                <mcv-password-usage-rules [passwordConfirmation]="passForm.value.password2"
                                          [password]="passForm.value.password"></mcv-password-usage-rules>
                <div class="form-row mt-3 d-flex justify-content-center">
                    <div class="col">
                        <button [disabled]="!passForm.valid || saving"
                                [promiseBtn]="saving"
                                class="btn btn-primary btn-block"
                                data-cy="submitpassword"
                                type="submit">
                            {{update ? 'Valider mon nouveau mot de passe' : 'Continuer'}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
