import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tpeStat'
})
export class TpeStatPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (value === 'new') {
            return '<span class="tags nouveau">Nouveau</span>';
        } else if (value === 'ok') {
            return '<span class="tags ok">Ok</span>';
        } else if (value === 'ko') {
            return '<span class="tags ko">Refusé</span>';
        } else if (value === 'deleted') {
            return '<span class="tags deleted">Annulé</span>';
        } else {
            return '<span class="tags deleted">' + value + '</span>';
        }
    }
}
