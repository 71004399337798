import {Injectable} from '@angular/core';
import {Intercom} from 'ng-intercom';
import {environment} from '@pro/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class IntercomService {

    constructor(public intercom: Intercom) {

    }

    bootWithValues(values: any) {
        if (values == null) {
            this.clearValues();
        } else {
            this.intercom.boot({
                app_id: environment.INTERCOM,
                name: `${values.firstname ?? 'N/A'} ${values.lastname ?? 'N/A'}`,
                email: values.email ?? '',
                mobile: values.mobile ?? '',
                affiliate: values.legalName ?? null
            });
        }
    }

    clearValues() {
        this.intercom.boot({
            app_id: environment.INTERCOM,
            widget: {
                'activator': '#intercom'
            }
        });
    }

}
