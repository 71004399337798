<mcv-nav-bar-inscription></mcv-nav-bar-inscription>
<div class="container revert-css">
    <div class="card border-light m-1 p-4">

        <div class="card-body">

            <div class="container pt-4 pb-4">
                <div class="text-center pt-2">
                    <h1>Professionnels du Tourisme, des Loisirs ou de la Culture ?</h1>
                </div>
            </div>

            <div class="container pt-2 pb-4">
                <div class="text-center pl-4 pr-4" style="font-size: 1.25rem;">
                    <p>Decouvrez <b>macartevacances</b> et développez rapidement votre <b>clientèle</b> auprès des <b>salariés</b>
                    </p>
                </div>
            </div>

            <div class="line">&nbsp;</div>

            <div class="container pt-0 pb-4">
                <div class="text-center pt-2">
                    <h1>Une carte de paiement pour les vacances<br> et les loisirs des salariés</h1>
                </div>
            </div>

            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col">
                        <div class="text-center">
                            <img alt="Ma carte vacances" class="img-fluid shadowed"
                                 src="{{baseUrl}}/img/Carte-Vacances-V1.png" style="width: 350px;">
                        </div>
                    </div>
                    <div class="col d-flex align-items-center">

                        <p class="content-headline"><b>macartevacances</b> permet aux <b>Comités d'Entreprise</b> (CE
                            / CSE) de distribuer le <b>budget vacances et loisirs</b> au travers d'une carte de paiement
                            <b>Mastercard<sup>®</sup></b></p>

                    </div>
                </div>
            </div>

            <div class="container pt-3">&nbsp;</div>

            <div class="container pt-1 pb-4 sticky-top">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <a [routerLink]="['/inscription/compte', fromAid ? { aid: fromAid } : { }]"
                           class="btn btn-primary btn-lg btn-block rounded-pill">Devenir membre du
                            programme macartevacances</a>
                    </div>
                </div>
            </div>


            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col d-flex align-items-center" style="text-align: justify; font-size: 1.25rem;">

                        <!--<p class="pt-5"><b>macartevacances</b> permet aux <b>Comités d'Entreprise</b> (CE / CSE) de distribuer leur <b>budget vacances et loisirs</b> au travers d'une carte de paiement <b>Mastercard</b>.</p>-->
                        <p class="">Accepter <b>macartevacances</b>, c'est permettre aux salariés de <b>dépenser leur
                            budget vacances et loisirs</b> chez vous et faire <b>connaitre</b> votre enseigne</p>
                    </div>
                    <div class="col">
                        <div class="text-center p-4">
                            <img alt="Ma carte vacances" class="img-fluid shadowed"
                                 src="{{baseUrl}}/img/images-sans-marques.png" style="width: 300px;">
                        </div>
                    </div>
                </div>
            </div>


            <div class="line">&nbsp;</div>


            <!-- http://gilles.bruno.caire.free.fr/index.php?option=com_docman&task=doc_download&gid=51&Itemid= -->

            <div class="container pt-4 pb-4">
                <div class="text-center">
                    <h1>Budget vacances et loisirs :<br>plus de pouvoir d'achat pour les salariés</h1>
                </div>
            </div>

            <div class="container pt-4 pb-4">
                <div class="text-center pl-4 pr-4" style="font-size: 1.25rem;">
                    <p>Capter du <b>chiffre d’affaires additionnel</b> et de <b>nouveaux clients</b>. Grâce au <b>budget
                        vacances et loisirs</b> des Comités d'Entreprise (CE / CSE), <b>les salariés</b> bénéficient
                        d’un fort gain de <b>pouvoir d’achat</b> exclusivement dédié aux dépenses <b>d’hébergement, de
                            transports et des loisirs culturels ou sportifs</b></p>
                </div>
            </div>

            <!--<div class="container pt-4 pb-4">
                <div class="text-center">
                    <h3>Budget CSE des vacances et loisirs : Du pouvoir d'achat pour les salariés</h3>
                </div>
            </div>-->

            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col-md-4 text-center">
                        <img class="img-fluid" src="{{baseUrl}}/img/Money-3.png" style="width: 45%">
                        <h2 class="text-primary pt-3">4 milliards</h2>
                        <div class="content-sub">d’euros par an</div>
                    </div>
                    <div class="col-md-4 text-center">
                        <img class="img-fluid" src="{{baseUrl}}/img/Earth-1.png" style="width: 45%">
                        <h2 class="text-primary pt-3">11,5 millions</h2>
                        <div class="content-sub"> de salariés</div>
                    </div>
                    <div class="col-md-4 text-center">
                        <img class="img-fluid" src="{{baseUrl}}/img/Calendrier-1.png" style="width: 45%">
                        <h2 class="text-primary pt-3">347 € de budget</h2>
                        <div class="content-sub"> en moyenne par salarié</div>
                    </div>
                </div>
            </div>

            <div class="line">&nbsp;</div>

            <div class="container pt-4 pb-4">
                <div class="text-center">
                    <h1>Gagnez en visibilité et diffusez vos offres</h1>
                </div>
            </div>

            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col-md-4">
                        <div class="text-center p-4">
                            <img alt="Ma carte vacances" class="img-fluid shadowed"
                                 src="{{baseUrl}}/img/justificatif.png"
                                 style="width: 300px;">
                        </div>
                    </div>
                    <div class="col-md-8 d-flex align-items-center" style="text-align: justify; font-size: 1.25rem;">

                        <div>
                            <p>Le programme <b>macartevances</b> permet de faire <b>découvrir</b> votre <b>enseigne et
                                vos prestations</b> auprès de tous les utilisateurs</p>
                            <p>Vos <b>offres et promotions</b> peuvent aussi être relayées à tous les bénéficiaires
                            </p>
                            <p>Grâce aux <b>outils de communication</b>, boostez votre visibilité avec des <b>campagnes
                                ciblées</b></p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="line">&nbsp;</div>

            <div class="container pt-4 pb-4">
                <div class="text-center">
                    <h1>Devenez affilié macartevacances !</h1>
                </div>
            </div>

            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col-md-4 text-center">
                        <img class="img-fluid" src="{{baseUrl}}/img/image22.jpeg" style="width: 45%">
                        <h5 class="text-primary pt-3">Simple, rapide et transparent</h5>
                        <div class="content-sub">Fini le papier, encaissez directement les dépenses</div>
                    </div>
                    <div class="col-md-4 text-center">
                        <img class="img-fluid" src="{{baseUrl}}/img/image23.jpeg" style="width: 45%">
                        <h5 class="text-primary pt-3">Une commission attractive</h5>
                        <div class="content-sub">Uniquement 3,15% sur les transactions. Sans frais fixes</div>
                    </div>
                    <div class="col-md-4 text-center">
                        <img class="img-fluid" src="{{baseUrl}}/img/image19.jpeg" style="width: 45%">
                        <h5 class="text-primary pt-3">Partenaire Mastercard<sup>®</sup></h5>
                        <div class="content-sub">Aucune mise à jour nécessaire du terminal de paiement</div>
                    </div>
                </div>
            </div>

            <div class="container pt-4 pb-4">
            </div>

            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col-md-5">
                        <button (click)="intercom.show()" class="btn btn-light btn-lg btn-block rounded-pill"
                                type="button">Contactez l'équipe
                            commerciale
                        </button>
                    </div>
                    <div class="col-md-7">
                        <button [routerLink]="['/inscription/compte', fromAid ? { aid: fromAid } : { }]"
                                class="btn btn-primary btn-lg btn-block rounded-pill"
                                type="button">Devenir membre
                            du programme
                            macartevacances
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<mcv-footer-inscription></mcv-footer-inscription>
