import {Component, Inject, OnInit} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {ActivatedRoute} from '@angular/router';
import {Intercom} from 'ng-intercom';
import {IntercomService} from '@pro/app/inscription/services/intercom.service';

@Component({
    selector: 'mcv-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

    fromAid: string;
    intercom: Intercom;

    constructor(@Inject(BASEURL) public baseUrl: string,
                private route: ActivatedRoute,
                private intercomService: IntercomService) {
        this.intercom = intercomService.intercom;
        this.fromAid = this.route.snapshot.queryParams['aid'] ?? null;
    }

    ngOnInit(): void {
        this.intercomService.clearValues();
    }
}
