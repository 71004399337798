import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {environment} from '@pro/environments/environment';
import {BASEURL} from '@mcv/config';
import {AffiliateUser, AppConfig, SingleResult} from '@mcv/core';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {AffiliateService, AffiliateUserService} from '@mcv/coreservices';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationsService {
    model = 'App';
    environnement = environment;

    constructor(private httpClient: HttpClient,
                @Inject(BASEURL) protected baseUrl: string,
                private affiliateUserService: AffiliateUserService,
                private affiliateService: AffiliateService,
                private authService: AuthService) {
        this.loadAppConfig();
    }

    loadAppConfig(): Observable<SingleResult<AppConfig>> {
        return this.httpClient
            .get<SingleResult<AppConfig>>(`${this.baseUrl}/affiliate-user/${this.model}/getConfig`)
            .pipe(
                map(r => r.data),
                tap((c) => {
                    this.environnement.client_env = c.env;
                    this.authService.user = c.user;
                }),
                switchMap(c => this.affiliateService.view((c.user as AffiliateUser).affiliate_public_id)),
                map(c => c.data),
                tap(affiliate => this.authService.affiliate = affiliate),
                catchError(err => {
                    this.authService.logout();
                    return of(null);
                })
            );
    }
}
