import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Affiliate, Merchant} from '@mcv/core';
import {McvnotifierService} from '@mcv/ui';
import {AuthService} from '@pro/app/auth/services/auth.service';
import {AffiliateTpeService, MerchantService} from '@mcv/coreservices';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'mcv-tpe-search-modal',
    templateUrl: './tpe-search-modal.component.html',
    styleUrls: ['./tpe-search-modal.component.scss']
})
export class TpeSearchModalComponent implements OnInit {

    @ViewChild('searchTpeModal') modal: TemplateRef<any>;

    selectedMerchant: Merchant;
    merchant$: Observable<Merchant[]>;
    merchantInput$: Subject<string> = new Subject<string>();
    affiliate: Affiliate;
    modalRef: BsModalRef;

    @Input() showModel: EventEmitter<any>;
    @Output() validTPE: EventEmitter<any> = new EventEmitter<any>();
    @Output() cantFoundTPE: EventEmitter<any> = new EventEmitter<any>();

    loading: boolean;
    saving: boolean;

    constructor(private notifierService: McvnotifierService,
                private authService: AuthService,
                private merchantService: MerchantService,
                private affiliateTpeService: AffiliateTpeService,
                private modalService: BsModalService) {
        this.affiliate = authService.affiliate;
        this.loadContent();
    }

    ngOnInit(): void {
        this.showModel.subscribe(() => {
            this.modalRef = this.modalService.show(this.modal, {class: 'modal-dialog-centered modal-lg'});
        });
    }

    testSelect(item): void {
        this.selectedMerchant = item;
    }

    clear(): void {
        this.selectedMerchant = null;
    }

    async saveTPE(): Promise<void> {
        try {
            this.saving = true;
            const newName = await this.affiliateTpeService.newTpename(this.affiliate.public_id).pipe(map(r => r.data)).toPromise();
            const params = {
                merchantId: this.selectedMerchant.mid,
                name: newName.name,
                affiliate_public_id: this.affiliate.public_id,
                mccCode: this.selectedMerchant.mccCode
            };
            await this.affiliateTpeService.add(params).toPromise();
            this.notifierService.success('Votre terminal de paiement a bien été créé');
            this.modalRef.hide();
            this.validTPE.emit();
            this.clear();
        } catch (e) {
            this.notifierService.error(e);
        } finally {
            this.saving = false;
        }
    }

    private loadContent() {
        this.merchant$ = concat(
            of([]), // default items
            this.merchantInput$.pipe(
                filter(v => !!v),
                debounceTime(500),
                distinctUntilChanged(),
                tap(_ => this.loading = true),
                switchMap(term => this.merchantService.search('filter', term)),
                map(
                    r => r.data
                ),
                catchError(_ => of([])), // empty list on error
                tap(_ => this.loading = false)
            )
        );
    }
}
