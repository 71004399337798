import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from '@pro/app/auth/services/auth.service';
import {McvnotifierService} from '@mcv/ui';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AutoLoginGuard implements CanActivate {
    constructor(private authService: AuthService,
                private notifierService: McvnotifierService,
                private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.isLoggedIn() ? this.router.navigate(['/pro/tableau-de-bord']) : true;
    }
}
