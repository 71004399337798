import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import Bugsnag from '@bugsnag/js';
import {ServiceLocator} from '@mcv/coreservices';
import {AuthService} from '@pro/app/auth/services/auth.service';
import version from './version.json';

Bugsnag.start({
    apiKey: '06c19c692643b16c042de84333ecbcac',
    appVersion: version.version,
    releaseStage: environment.client_env,
    onError: function (event) {
        const authService = ServiceLocator.injector.get(AuthService);
        event.setUser(authService.user.public_id, null, authService.user.fullname);
    }
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
