import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BASEURL} from '@mcv/config';
import {Affiliate, AffiliateUser, AuthResult, BaseUser, SingleResult} from '@mcv/core';
import * as moment from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

const TOKEN = 'pro_id_token';
const EXPIREAT = 'pro_expires_at';
const SUPERADMIN = 'pro_superadmin';

/**
 * @todo : à factoriser avec le CseEmploye
 */
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    model = 'affiliate-user';
    affiliate$: BehaviorSubject<Affiliate> = new BehaviorSubject<Affiliate>(null);
    affiliateUser$: BehaviorSubject<AffiliateUser> = new BehaviorSubject<AffiliateUser>(null);
    superAdmin = false;

    constructor(private httpClient: HttpClient, @Inject(BASEURL) protected baseUrl: string) {
        this.superAdmin = !!window.localStorage.getItem(SUPERADMIN);
    }

    private _user: BaseUser;

    get user(): BaseUser {
        return this._user;
    }

    set user(user: BaseUser) {
        this._user = user;
        this.affiliateUser$.next(this._user as AffiliateUser);
    }

    private _affiliate: Affiliate;

    get affiliate(): Affiliate {
        return this._affiliate;
    }

    set affiliate(affiliate: Affiliate) {
        this._affiliate = affiliate;
        this.affiliate$.next(this._affiliate);
    }

    // tslint:disable-next-line:variable-name
    login(formData: any): Observable<any> {
        return this.httpClient
            .post<SingleResult<AuthResult>>(`${this.baseUrl}/${this.model}/login`, formData)
            .pipe(
                map(r => r.data),
                tap(r => this.setSession(r))
            );

    }

    resetPassword(formData: any): Observable<any> {
        return this.httpClient
            .post<any>(`${this.baseUrl}/${this.model}/resetpassword`, formData);
    }

    confirmPasswordReset(formData: any): Observable<any> {
        return this.httpClient
            .put<any>(`${this.baseUrl}/${this.model}/confirmPasswordReset`, formData);
    }

    setSession(authResult: AuthResult): void {
        const expiresAt = moment()
            .add(authResult.expiresIn, 'second');
        if (authResult.superAdmin) {
            localStorage.setItem(SUPERADMIN, '1');
            this.superAdmin = true;
        }
        localStorage.setItem(TOKEN, authResult.token);
        localStorage.setItem(EXPIREAT, JSON.stringify(expiresAt.valueOf()));
    }

    logout(): void {
        localStorage.removeItem(SUPERADMIN);
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(EXPIREAT);
    }

    isLoggedIn(): boolean {
        return localStorage.getItem(EXPIREAT) && moment()
            .isBefore(this.getExpiration());
    }

    isLoggedOut(): boolean {
        return !this.isLoggedIn();
    }

    getExpiration(): any {
        const expiration = localStorage.getItem(EXPIREAT);
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    getToken(): string {
        return localStorage.getItem(TOKEN);
    }

    logAs(token: string): Observable<AuthResult> {
        return this.httpClient.post<AuthResult>(`${this.baseUrl}/${this.model}/logas`, {token});
    }

    checkIp(): Observable<SingleResult<{ mustCaptcha: boolean }>> {
        return this.httpClient.get<SingleResult<{ mustCaptcha: boolean }>>(`${this.baseUrl}/${this.model}/App/checkIp`);
    }
}
