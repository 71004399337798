import {Pipe, PipeTransform} from '@angular/core';
import {Legal} from '@mcv/core';

@Pipe({
    name: 'legalToHtml'
})
export class LegalToHtmlPipe implements PipeTransform {

    transform(value: Legal, ...args: unknown[]): unknown {
        return `${value.business.legalName}, <small>${value.business.address1}, ${value.business.postcode} ${value.business.city}</small>`;
    }

}
