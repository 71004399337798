import {Pipe, PipeTransform} from '@angular/core';
import {Merchant} from '@mcv/core';

@Pipe({
    name: 'merchantToHtml'
})
export class MerchantToHtmlPipe implements PipeTransform {

    transform(value: Merchant, ...args: unknown[]): unknown {
        let address = `${value.address ?? ''} ${value.postcode ?? ''} ${value.city ?? ''}`;
        if (address.replace(/\s/g, '').length !== 0) {
            address = `, ${address}`;
        }
        return `${value.mid}, ${value.name.trim()}<small>${address}</small>`;
    }

}
