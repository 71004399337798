<div class="m-5 text-center logo-outside">
    <img alt="" src="{{baseUrl}}/img/logo.png" width="330">
</div>

<div class="container">
    <div class="card border-light m-1 p-4">

        <div class="card-body">
            <form (ngSubmit)="savePassword()" [formGroup]="form" class="form-horizontal">

                <h2 class="card-title">Création de votre compte macartevacances</h2>

                <mcv-inscription-step [step]=step></mcv-inscription-step>

                <p class="">Veuillez créer votre mot de passe</p>

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="email">Identifiant</label>
                        <input autocomplete="username email"
                               class="form-control"
                               disabled
                               formControlName="email"
                               id="email"
                               required
                               type="email">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="password">Mot de passe</label>
                        <input autocomplete="new-password"
                               class="form-control"
                               formControlName="password"
                               id="password"
                               required
                               type="password">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="password2">Confirmation du mot de passe</label>
                        <input autocomplete="new-password"
                               class="form-control"
                               formControlName="password2"
                               id="password2"
                               required
                               type="password">
                    </div>
                </div>

                <mcv-password-usage-rules [passwordConfirmation]="form.value.password2"
                                          [password]="form.value.password"></mcv-password-usage-rules>

                <div class="form-row mt-3">
                    <div class="col-md-6 offset-md-3">
                        <button [disabled]="!form.valid || saving"
                                [promiseBtn]="saving"
                                class="btn btn-primary btn-block"
                                type="submit">
                            Valider mon mot de passe
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
